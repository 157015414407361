.box {
    position:relative;
    //border:1px solid $border;
    padding:10px;
    box-shadow:5px 5px 0 0 black;
    background-color:rgba(0, 0, 0, 0.5);
    opacity:0.5;
    transition:0.1s;
    text-align:center;

    @include screen($xs) {
        text-align:left;
    }

    &:hover, &:focus {
        opacity:1;
    }

    a {
        color:white;
    }
}

.box-title {
    position:absolute;
    top:0;
    left:50%;
    height:31px;
    padding:0 15px;
    color:white;
    transform:translateX(-50%) translateY(-50%);
    font-size:24px;
    line-height:30px;
    white-space:nowrap;

    &:before {
        z-index:-1;
        content:'';
        position:absolute;
        left:0;
        width:100%;
        height:50%;
        background-color:rgba(0, 0, 0, 0.5);
        box-shadow:7px 2px 0 -2.5px black;
    }
}
