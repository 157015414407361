$base-font-size:16px;

$xx:400;
$xs:768;
$sm:1024;
$md:1280;
$lg:1600;

$background:#19A5F0;
$border:black;

