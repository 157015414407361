* {
    box-sizing:border-box;
}

body {
    background-color:black;
    background-size:cover;
    background-position:fixed;


    @include screen($xs) {
        overflow:hidden;
    }
}

html, body {
    @include screen($xs) {
        height:100%;
    }
}

body {
    padding:20px;
}
