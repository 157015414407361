@mixin animation($animation) {
    -webkit-animation:$animation;
    -moz-animation:$animation;
    animation:$animation;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin filter($filter) {
    -webkit-filter:$filter;
    -moz-filter:$filter;
    filter:$filter;
}

@mixin opacity($opacity) {
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(opacity=$opacity)";
    filter:alpha(opacity=$opacity);
    -moz-opacity:$opacity;
    -khtml-opacity:$opacity;
    opacity:$opacity;
}

@mixin display-flex () {
    display:-webkit-box;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
    display:flex;
}

@mixin flex-flow ($flex-flow) {
    -webkit-flex-flow:$flex-flow;
    flex-flow:$flex-flow;
}

@mixin font-styling ($font-styling-margin, $font-styling-color, $font-styling-size, $font-styling-family, $font-styling-lineheight:1.5em) {
    margin:$font-styling-margin;
    color:$font-styling-color;
    font-size:$font-styling-size;
    font-family:$font-styling-family;
    line-height:$font-styling-lineheight;
}

@mixin transition ($transition) {
    -webkit-transition:$transition;
    -moz-transition:$transition;
    -o-transition:$transition;
     transition:$transition;
}

@mixin transform ($transform) {
    -webkit-transform:$transform;
    -moz-transform:$transform;
    -ms-transform:$transform;
    -o-transform:$transform;
}

@mixin background-size ($background-size) {
    -webkit-background-size:$background-size;
    -moz-background-size:$background-size;
    -o-background-size:$background-size;
    background-size:$background-size;
}

@mixin border-radius ($border-radius) {
    -webkit-border-radius:$border-radius;
    -moz-border-radius:$border-radius;
    border-radius:$border-radius;
}

@mixin border-top-left-radius ($border-radius) {
    -webkit-border-top-left-radius:$border-radius;
    -moz-border-top-left-radius:$border-radius;
    border-top-left-radius:$border-radius;
}

@mixin border-top-right-radius ($border-radius) {
    -webkit-border-top-right-radius:$border-radius;
    -moz-border-top-right-radius:$border-radius;
    border-top-right-radius:$border-radius;
}

@mixin border-bottom-left-radius ($border-radius) {
    -webkit-border-bottom-left-radius:$border-radius;
    -moz-border-bottom-left-radius:$border-radius;
    border-top-bottom-radius:$border-radius;
}

@mixin border-botom-right-radius ($border-radius) {
    -webkit-border-bottom-right-radius:$border-radius;
    -moz-border-bottom-right-radius:$border-radius;
    border-bottom-right-radius:$border-radius;
}

@mixin box-shadow ($box-shadow...) {
    -webkit-box-shadow:$box-shadow;
    -moz-box-shadow:$box-shadow;
    box-shadow:$box-shadow;
}

@mixin box-sizing ($box-sizing) {
    -webkit-box-sizing:$box-sizing;
    -moz-box-sizing:$box-sizing;
    box-sizing:$box-sizing;
}

@mixin linear-gradient($fromColor, $toColor) {
    background-color:$toColor; /* Fallback Color */
    background-image:-webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
    background-image:-webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image:-moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
    background-image:-ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
    background-image:-o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
    background-image:linear-gradient(top, $fromColor, $toColor);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin calc($property, $calc) {
    #{$property}:-moz-calc(#{$calc});
    #{$property}:-webkit-calc(#{$calc});
    #{$property}:-o-calc(#{$calc});
    #{$property}:calc(#{$calc});
}

@mixin clearfix {
    content:"";
    display:block;
    clear:both;
}

@mixin screen($width, $height: 0) {
    @media only screen and (min-width:$width+'px') and (min-height:$height+'px') { @content; }
}

@mixin perspective($perspective) {
    -webkit-perspective:$perspective;
    -moz-perspective:$perspective;
    perspective:$perspective;
}

